$sidebar-width: 350px;
$sidebar-vgap: 20px;

@font-face {
    font-family: "GTAmerican";
    src: url(./fonts/GT-America-Standard-Regular.otf) format("opentype");
    font-weight: normal;
}

@font-face {
    font-family: "GTAmerican";
    font-weight: bold;
    src: url(./fonts/GT-America-Standard-Bold.otf) format("opentype");
}

html,
body {
    width: 100vw;
    height: 100vh;
    margin: 0px;
    padding: 0px;
    font-size: 12px;
    color: #919496;
    font-family: GTAmerican;
}
#root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.App {
    text-align: center;
    width: 100vw;
    height: 100vh;
}

h2 {
    font-size: 1.3rem;
    font-weight: 900;
}
h3 {
    font-size: 1.2rem;
    font-weight: normal;
}
h1,
h2,
h3,
h4 {
    margin: 0px;
    padding: 0px;
}

p {
    font-size: 0.9rem;
}
.help-tab {
    text-align: left;
    overflow-y: auto;
    img {
        width: 100%;
    }
    ul.dashed {
        > li {
            text-indent: 5px;
        }
        li::before {
            content: "-";
            text-index: 5px;
            color: black;
            margin-right: 5px;
        }
    }
}

.cards {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow-x: auto;
    flex: 1;
    align-items: flex-start;

    .card {
        min-width: 350px;
        height: 100%;
        box-sizing: border-box;
        padding: 5px 10px 0px 20px;
        //   border: 1px solid black;
        margin-right: 0px;
    }

    .CCFCard{
        display:flex;
        flex-direction: column;
        p{
            flex:1
        }
    }
    .basic {
        span {
            font-weight: bold;
        }
    }
}

.main-page {
    width: 100vw;
    height: 100vh;
}
.map {
    width: 100%;
    height: 100%;
}
.selected {
    font-weight: bold;
}
.overlay.details {
    text-align: left;
    z-index: 1;
    z-index: 1;
    bottom: 20px;
    height: 260px;
    min-width: $sidebar-width;
    max-width: 97vw;
    padding: 0px 0px 0px 0px;

    -webkit-box-shadow: 0px -4px 16px 3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px -4px 16px 3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px -4px 16px 3px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    .selector-cards {
        display: flex;
        flex-direction: row;
        flex: 1;
    }
}
.download-assets {
    cursor: pointer;
    svg {
        margin-right: 10px;
    }
}
.details-selector {
    ul {
        border-right: 1px solid lightgrey;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    li {
        font-size: 1.3rem;
        border-bottom: 1px solid lightgrey;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .selected {
        font-weight: 700;
    }
}
.overview {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid lightgrey;
    align-items: center;
    .boundary-type-selector {
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        height: 100%;
        height:50.19px;
        align-items: center;

        .boundary-type-selector-type {
            margin-right: 0px;
            cursor: pointer;
            background-color: grey;
            height: 100%;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0px 20px;
            &:last-child {
                border-right: 1px solid grey;
            }
            &.selected {
                background-color: white;
                color: grey;
            }
        }
    }
    .top-stats {
        display: flex;
        flex-direction: row;
        flex: 1;
        overflow-x: auto;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
        }

        p {
            padding: 0px 15px;
            white-space: nowrap;
            span {
                margin-left: 5px;
                white-space: nowrap;
            }
            font-size: 1.3rem;
        }
    }
}
.range-legend {
    display: flex;
    flex-direction: column;
}
.range-legend-bar {
    height: 15px;
    width: 100%;
    border: 1px solid grey;
    box-sizing: border-box;
}

.range-legend-labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tabs {
    display: flex;
    background-color: #919496;
    font-size: 2rem;
    color: white;
    justify-content: space-around;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
    div {
        box-sizing: border-box;
        flex: 1;
        padding: 10px;
    }
    .selected {
        background-color: white;
        color: #919496;
    }
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}
.details-header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 20px;
    box-sizing: border-box;
    max-width: calc(100vw -20px);
    span {
        cursor: pointer;
    }
}
.overlay {
    box-sizing: border-box;
    padding: 15px;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75);
    position: absolute;
    background-color: white;
    border-radius: 2px;
    left: 2vw;
    z-index: 1;
}
.info {
    position: absolute;
    top: 20px;
    text-align: left;
    width: $sidebar-width;
        height: 135px;
        p {
            display: block;
        }
    h3 {
        font-size: 1rem;
    }
}
.Legend .content {
    padding: 20px;
    flex: 1;
    overflow-y: auto;
}
.Legend {
    padding: 0px;
    position: absolute;
    z-index: 100;
        top: calc(121px + 20px + 20px);
        height: calc(100vh - 450px);
    background-color: white;

    width: $sidebar-width;
    display: flex;
    flex-direction: column;
    h3 {
        margin: 10px 0px 5px 0px;
        text-align: left;
        font-weight: 700;
    }
    ul {
        margin-top: 10px;
    }
    section {
        margin-bottom: 20px;
    }
}
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.simple-bar-chart {
    display: flex;
    flex-direction: column;
    h3 {
        margin-bottom: 20px;
    }
    .bar-chart {
        flex: 1;
        display: grid;
        grid-template-columns: 0.2fr 1fr 0.2fr;
        grid-column-gap: 10px;
        align-items: center;
        overflow-y: auto;
    }
    .bar {
        height: 10px;
        background-color: "red";
    }
}

.placeholder {
    flex: 1;
    display: grid;
    justify-content: center;
    align-content: center;
    h2 {
        font-size: 2rem;
    }
}
.language-card {
    ul {
        box-sizing: border-box;
        padding: 20px 10px 20px 0px;
        width: 100%;
        li {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            width: 100%;
        }
    }
}
.pie-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h2 {
        margin-bottom: 20px;
    }
    .pie-card-content {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: top;
    }
    .labels {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 5px 0px 0px 10px;
    }
    .label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .label-text {
            white-space: nowrap;
        }
    }
    .bar {
        width: 20px;
        height: 10px;
        margin-right: 10px;
    }
}
.placeholder {
    h2 {
        font-size: 1.2rem;
    }
}
.assets-placeholder {
    flex: 1;
    display: flex;
    justify-content: center;
}
.asset-card {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    flex: 1;
    box-sizing: border-box;
    max-height: 160px;

    h2 {
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
    img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }
    ul {
        //display: inline-flex;
        //writing-mode: vertical-lr;
        //flex-wrap: wrap;
        //align-content: flex-start;
        box-sizing: border-box;
        padding: 20px 0px 10px 0px;
        height: 170px;
        // max-height: 220px;
        // flex-direction: row;
        // flex: 1;
        display: flex;
        flex: 1;
        flex-flow: column wrap;
        //    grid-column-gap: 10px;
        ///  display: grid;
        // grid-template-columns: repeat(auto-fit(400px));
        // grid-template-rows: max-content;

        li {
            box-sizing: border-box;
            padding: 5px 5px 5px 0px;
            writing-mode: horizontal-tb;
            width: 267px;
        }
    }
    h3 {
        font-size: 1rem;
        font-weight: 700;
        margin: 0px;
        padding: 0px;
    }

    p {
        font-size: 0.8rem;
        padding: 0px;
        margin: 0px;
    }
}
.category-entry {
    display: flex;
    align-items: flex-start;
    .category-name {
        margin-top: -2px;
        text-align: left;
        font-weight: 700;
        p {
            margin: 2px 0px 5px;

            font-weight: 200;
        }
    }
}
.category-marker {
    width: 30px;
    height: 10px;
    box-sizing: border-box;
    padding: 4px;
    border: 1px solid black;
    margin-right: 10px;
}
.facilities-selector {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    text-align: left;

    .facilities-section-header {
        display: flex;
        align-items: center;
    }
    svg {
        cursor: pointer;
    }
    h3 {
        margin-bottom: 10px;
    }
    h4 {
        text-align: left;
        cursor: pointer;
        font-weight: 500;
    }
    section {
        margin-bottom: 10px;
    }
    ul {
        box-sizing: border-box;
        padding: 0px 0px 0px 12px;
        li {
            padding: 2px 0px;
        }
    }
}

.fact {
    display: flex;
    flex-direction: row;
    flex-wrap: row;
    justify-content: flex-start;
    .fact-name {
        margin-right: 10px;
    }
    p {
        font-size: 1rem;
    }

    .fact-text {
        font-weight: 700;
        color: grey;
    }
    .fact-value {
        font-weight: 700;
        color: #dd2230;
    }
}
.nrfu-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.share-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.share-button {
    box-shadow: inset 0px 1px 0px 0px #9acc85;
    background: linear-gradient(to bottom, #74ad5a 5%, #68a54b 100%);
    background-color: #74ad5a;
    border: 1px solid #3b6e22;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 6px 12px;
    text-decoration: none;
}
.share-button:hover {
    background: linear-gradient(to bottom, #68a54b 5%, #74ad5a 100%);
    background-color: #68a54b;
}
.share-button:active {
    position: relative;
    top: 1px;
}
.progressBar {
    width: 300px;
    .outer {
        box-sizing: border-box;
        padding: 2px 0px;
        border: 1px solid black;
    }
    .inner {
        height: 10px;
        background-color: #faaf12;
    }
}

.outreach-tab{
    .key{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: start;
        
        p{
            text-align: center;
            
            max-width:94px;
        }
    }
}

.outreach-step{
    text-align: left;
    span{
        font-weight: bold;
    }
}

.selfresponse,
.elp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0px !important;
    text-align: center;
    width: 100%;

    h3 {
        font-weight: bold;
        font-size: 20px;
    }
    p.bigPC {
        margin: 0px;
        font-size: 50px;
        color: red;
    }
}
.elp {
    justify-content: space-around;
}
.print {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
}

.print-dialog {
    width: 100vw;
    height: 100vh;
    padding: 50px;
    margin: 0px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    z-index: 200;
    position: absolute;
    top: 0px;
    left: 0px;
    .inner {
        display: flex;
        flex: 1;
        background-color: white;
        border-radius: 5px;
        flex-direction: column;
        box-sizing: border-box;
        padding: 20px;
    }
}
.card.participation{
    p{
        color:red;
        font-size:25px;
    }

    .participation-grid{
        display:grid;
        grid-template-columns: 1fr ;
        margin-top:20px;
        grid-template-rows: 1fr 10px;
        align-items: center;
        justify-items: center;
    }

}

    .participation-explanation{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width:500px;
    }